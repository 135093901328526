<template>
  <div>

    <van-form @submit="save">
      <van-field v-model="model.ordererName" label="姓名" input-align="right" readonly />
      <van-field v-model="model.ordererPhone" label="手机号" input-align="right" readonly />
      <div style="margin-left: 16px;">
        <van-uploader v-model="ordererFile" :after-read="afterRead" @oversize="onOversize" disabled></van-uploader>
      </div>
      <div style="margin-left: 16px;">
        <van-uploader v-model="driverFile" :after-read="afterRead" @oversize="onOversize"></van-uploader>
      </div>
      <div style="margin: 16px;">
        <van-button round block type="info">保存</van-button>
      </div>
    </van-form>
  </div>

</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  data() {
    return {
      ordererFile: [],
      driverFile: [],
      model: {
        ordererName: "",
        ordererPhone: "",
        ordererPic: 0,
        ordererCreateTime: new Date().toISOString(),
        driverPic: 0,
      },
    };
  },
  methods: {
    afterRead(file) {
      let that = this;
      that.thisFile = file.file;
      let fd = new FormData();
      fd.append("data", file.file);
      that.axios3.post("FileReceive/Recv", fd).then((response) => {
        if (response.data.code == 101) {
          that.$notify({ type: "success", message: "上传成功" });
          that.model.driverPic = response.data.data.id;
        }
      });
    },
    save() {
      let that = this;
      that.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
        overlay: true,
      });
      that.axios
        .post("ERP_Orderer_Driver/SiJi_UpdateModel", that.model)
        .then((response) => {
          if (response.data.code == 101) {
            that.$toast.clear();
          }
        });
    },
    onOversize() {},
  },
  mounted() {},
};
</script>

<style></style>